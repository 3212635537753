.screenContainer {
  background-color: #171712;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.screenSubContainer {
  width: 1050px;
}
.header {
  color: white;
  font-size: 36px;
  font-weight: 600;
  margin: 25px 0 18px;
}
.description {
  margin: 5px 0;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

.subHeader {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin: 30px 0 10px;
}
.subSubHeader {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin: 30px 0 10px;
}
table,
tr,
td,
th {
  border: 1px solid white;
  color: white;
}
table {
  border-collapse: collapse;
  margin: 10px 0;
}
td,
th {
  padding: 7px 12px;
}
th {
  font-weight: 800;
  letter-spacing: 0.5px;
}
td {
  font-weight: 300;
}
.list {
  color: white;
  margin: 5px 0;
}
.list > li {
  margin: 8px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}
.list > li > b {
  font-weight: 800;
}
.link {
  color: rgb(171, 25, 255);
}
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;
}
.bottom > .button {
  text-decoration: 0;
  color: #fff;
  border: 2.5px solid white;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .screenSubContainer {
    width: 750px;
  }
}
@media screen and (max-width: 850px) {
  .screenSubContainer {
    width: 500px;
  }
  .header {
    font-size: 28px;
    margin: 20px 0 15px;
  }
  .description {
    margin: 4px 0;
    font-size: 14px;
    line-height: 21px;
  }
  .imgI {
    display: none;
  }

  .subHeader {
    color: white;
    font-size: 22px;
    font-weight: 600;
    margin: 24px 0 8px;
  }
  .subSubHeader {
    font-size: 17.5px;
    margin: 24px 0 8px;
  }
  .list {
    color: white;
    margin: 4px 0;
  }
  .list > li {
    margin: 6.5px 0;
    font-size: 14px;
    line-height: 20px;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
  }
  .bottom > .button {
    border: 2px solid white;
    padding: 7px 18px;
    border-radius: 6px;
    font-size: 17px;
  }
}
@media screen and (max-width: 550px) {
  .screenSubContainer {
    width: 300px;
  }
  .header {
    font-size: 22px;
    margin: 18px 0 12px;
  }
  .description {
    margin: 4px 0;
    font-size: 11.5px;
    line-height: 17px;
  }
  .imgI {
    display: none;
  }

  .subHeader {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 18px 0 6px;
  }
  .subSubHeader {
    font-size: 15px;
    margin: 18px 0 6px;
  }
  .list {
    color: white;
    margin: 4px 0;
  }
  .list > li {
    margin: 4.5px 0;
    font-size: 11.5px;
    line-height: 20px;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
  }
  .bottom > .button {
    border: 1px solid white;
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 12px;
  }
}

.home {
  background-color: #171712;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
}
.home > .img {
  width: 1050px;
  margin-top: 50px;
}
.home > .info {
  position: relative;
  top: -320px;
  margin-bottom: -50px;
}
.home > .info > .title {
  font-size: 36px;
  color: rgb(255, 255, 255);
  font-weight: 800;
  text-align: center;
}
.home > .info > .desc {
  width: 900px;
  font-weight: 400;
  font-size: 17px;
  color: rgb(255, 255, 255);
  text-align: center;
}
.home > .header {
  font-size: 30px;
  color: rgb(255, 255, 255);
  font-weight: 800;
  width: 1050px;
}
.home > .items {
  width: 1050px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  row-gap: 20px;
}
.home > .items > .link {
  text-decoration: none;
}
.home > .items > .link > img {
  width: 250px;
}
.home > .items > .link > div {
  font-size: 18px;
  color: rgb(255, 255, 255);
  font-weight: 400;
  margin-top: 5px;
  text-decoration: none;
  text-align: center;
  width: 250px;
}

@media screen and (max-width: 1200px) {
  .home > .img {
    width: 750px;
  }
  .home > .info {
    position: relative;
    top: -250px;
    margin-bottom: -90px;
  }
  .home > .info > .title {
    font-size: 28px;
  }
  .home > .info > .desc {
    width: 600px;
    font-size: 15px;
  }
  .home > .header {
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    width: 750px;
  }
  .home > .items {
    width: 750px;
    margin-top: 10px;
    gap: 14px;
    row-gap: 18px;
  }
  .home > .items > .link > img {
    width: 240.5px;
  }
  .home > .items > .link > div {
    width: 240.5px;
  }
}
@media screen and (max-width: 800px) {
  .home > .img {
    width: 500px;
  }
  .home > .info {
    position: relative;
    top: -180px;
    margin-bottom: -90px;
  }
  .home > .info > .title {
    font-size: 20px;
  }
  .home > .info > .desc {
    width: 420px;
    font-size: 13px;
  }
  .home > .header {
    font-size: 24px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    width: 500px;
  }
  .home > .items {
    width: 500px;
    margin-top: 10px;
    gap: 14px;
    row-gap: 18px;
  }
  .home > .items > .link > img {
    width: 157px;
  }
  .home > .items > .link > div {
    width: 157px;

    font-size: 12px;
  }
}
@media screen and (max-width: 540px) {
  .home > .img {
    width: 300px;
  }
  .home > .info {
    position: relative;
    top: -100px;
    margin-bottom: -70px;
  }
  .home > .info > .title {
    font-size: 11px;
  }
  .home > .info > .desc {
    width: 280px;
    font-size: 10px;
  }
  .home > .header {
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 800;
    width: 300px;
  }
  .home > .items {
    width: 300px;
    margin-top: 10px;
    gap: 14px;
    row-gap: 18px;
  }
  .home > .items > .link > img {
    width: 143px;
  }
  .home > .items > .link > div {
    width: 143px;

    font-size: 11px;
  }
}
